import {
  AuthMenuButton,
  AuthMenuDetails,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@xpanseinc/nebula';
import { LogoutBoxIcon } from '@xpanseinc/nebula-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { version } from '../../package.json';

const UserMenu = () => {
  const { logout, user } = useAuth0();
  const userInitials = user?.name
    ?.split(' ')
    .map((n) => n[0])
    .slice(0, 2)
    .join('')
    .toUpperCase();

  return (
    <>
      {user && (
        <Menu>
          <MenuButton as={AuthMenuButton}>{userInitials}</MenuButton>
          <MenuList>
            <MenuItem disabled>
              <AuthMenuDetails
                email={user?.email}
                organization=""
                username={user?.name}
                userRole=""
                version={`Podium: v${version}`}
              />
            </MenuItem>
            <MenuDivider />
            <MenuItem
              icon={<LogoutBoxIcon />}
              onClick={() =>
                logout({
                  returnTo: window.location.origin,
                })
              }
            >
              Sign out
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </>
  );
};

export default UserMenu;
