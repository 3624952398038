import { Alert, VStack } from '@xpanseinc/nebula';

const RequestError = ({ message, errorMessage }: { message: string; errorMessage: string }) => (
  <Alert status="negative">
    <VStack spacing={16}>
      <h3>{message}</h3>
      <h3>{errorMessage}</h3>
    </VStack>
  </Alert>
);

export default RequestError;
