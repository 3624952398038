import React, { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { Logo } from '@xpanseinc/nebula';
import { PermissionAccessEnum, Permission } from '@xpanseinc/authorization-service-api-rest';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '@/constants';
import { useGetCurrentUser } from '@/hooks/useUsers';
import { useAppContext } from '..';

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  justify-content: center;
`;

const AuthConsumer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isLoading: isFetchingAuth0User, loginWithRedirect, user } = useAuth0();

  const { setCurrentUser } = useAppContext();

  const {
    error,
    isLoading: isFetchingPodiumUser,
    isSuccess,
  } = useGetCurrentUser({
    enabled: !!user,
    onSuccess: ({ permissions, ...rest }) => {
      const adminPermissions =
        permissions?.filter(
          ({ hasPermission, isAdminPermission }) => hasPermission && isAdminPermission,
        ) || [];

      // For permissions pertaining to a product,
      // permission.hasPermission is true only for the most permissive access
      // Meaning if a user has Read, Edit and Create permissions, create is true while edit and read are false
      // The purpose of this is to provide semantic property definitions for permissions granted to the user
      // rather than having to be aware that if a user has create access then read and edit are implied throughout the app

      const productPermissions =
        permissions?.reduce((accumulator: Permission[], permission) => {
          const { access, hasPermission, isAdminPermission } = permission;
          if (isAdminPermission) {
            return accumulator;
          }
          if (hasPermission) {
            if (access === PermissionAccessEnum.Create) {
              accumulator.push(
                permission,
                { ...permission, access: PermissionAccessEnum.Edit },
                { ...permission, access: PermissionAccessEnum.Read },
              );
              return accumulator;
            }

            if (access === PermissionAccessEnum.Edit) {
              accumulator.push(permission, { access: PermissionAccessEnum.Read, ...permission });
              return accumulator;
            }

            accumulator.push(permission);

            return accumulator;
          }
          return accumulator;
        }, []) || [];
      setCurrentUser({ ...rest, adminPermissions, productPermissions });
    },
  });

  const isLoading = isFetchingAuth0User || isFetchingPodiumUser;

  const authenticate = useCallback(async () => {
    if (!isLoading && !user) {
      await loginWithRedirect();
    }
  }, [loginWithRedirect, isLoading, user]);

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  return (
    <>
      {isLoading && (
        <Loading>
          <Logo variant="icon" width="8rem" />
        </Loading>
      )}
      {isSuccess && <>{children}</>}
      {error && <h1>{error?.message}</h1>}
    </>
  );
};

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    redirectUri={window.location.href}
    audience={AUTH0_AUDIENCE}
  >
    <AuthConsumer>{children}</AuthConsumer>
  </Auth0Provider>
);
