import React from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { Button, HStack } from '@xpanseinc/nebula';
import { XmarkIcon } from '@xpanseinc/nebula-icons';
import { useAppContext } from '@/context';

export const ModalElement = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: var(--space-00);
  padding: 0 72px;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  height: 80px;
  font-size: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 8px;

  > svg {
    cursor: pointer;
  }
`;

export const ModalTitle = styled.h1`
  font-size: 24px;
  margin: 0 0 24px 0;
  padding: 0 8px;
`;

export const ModalBody = styled.div`
  flex: 1;
  padding: 0 8px;
  overflow: scroll;
`;

export const ModalFooter = styled.div`
  height: 80px;
  margin: 0 8px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--space-04);
`;

interface ModalWrapperProps {
  children: React.ReactNode;
  title: string;
}

export const ModalWrapper = ({ children, title }: ModalWrapperProps) => {
  const { setModalContent } = useAppContext();

  const toggleModal = () => setModalContent(null);

  return (
    <ModalElement>
      <ModalHeader>
        <XmarkIcon size={24} onClick={toggleModal} />
      </ModalHeader>
      <ModalTitle>{title}</ModalTitle>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <HStack spacing={16}>
          <Button onClick={toggleModal}>Close</Button>
        </HStack>
      </ModalFooter>
    </ModalElement>
  );
};

const Modal = () => {
  const { modalContent } = useAppContext();

  return modalContent ? ReactDOM.createPortal(<>{modalContent}</>, document.body) : null;
};

export default Modal;
