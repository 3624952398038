import React, { createContext, useContext, useMemo, useState } from 'react';
import type { XpanseUser } from '@/types';

export interface ContextInterface {
  currentUser: XpanseUser;
  setCurrentUser: (currentUser: XpanseUser) => void;
  modalContent: JSX.Element | null;
  setModalContent: (content: JSX.Element | null) => void;
}

export const AppContext = createContext<ContextInterface | null>(null);

export const useAppContext = () => {
  const ctx = useContext(AppContext);
  if (!ctx) throw new Error('Context must be used inside a provider');
  return ctx;
};

export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);
  const [currentUser, setCurrentUser] = useState<XpanseUser>({} as XpanseUser);

  const contextValue: ContextInterface = useMemo(
    () => ({
      modalContent,
      setModalContent,
      currentUser,
      setCurrentUser,
    }),
    [modalContent, setModalContent, currentUser, setCurrentUser],
  );

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
