import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider, ToastProvider } from '@xpanseinc/nebula';
import { ContextProvider } from '@/context';
import { AuthProvider } from '@/context/auth';
import App from './App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Could not find root');
}

const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <ContextProvider>
        <QueryClientProvider client={queryClient}>
          <ToastProvider>
            <Router>
              <AuthProvider>
                <App />
              </AuthProvider>
            </Router>
          </ToastProvider>
        </QueryClientProvider>
      </ContextProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
