import { NavLink } from 'react-router-dom';
import { SideNav, SideNavDivider, SideNavItem, SideNavLogo } from '@xpanseinc/nebula';
import { FileListIcon, HomeIcon, LockIcon, Person2Icon, ToolsIcon } from '@xpanseinc/nebula-icons';
import { UserMenu } from '@/components';

const navLinks = [
  { path: '/', title: 'Organizations', Icon: HomeIcon },
  { path: '/users', title: 'Users', Icon: Person2Icon },
  { path: '/permissions', title: 'Permissions', Icon: LockIcon },
  { path: '/logs', title: 'Logs', Icon: FileListIcon },
  { path: '/tools', title: 'Tools', Icon: ToolsIcon },
];

const SideNavigation = () => (
  <SideNav>
    <SideNavLogo />
    {navLinks.map(({ path, title, Icon }) => (
      <SideNavItem key={path} title={title}>
        <NavLink to={path} aria-label={title}>
          <Icon size={20} />
        </NavLink>
      </SideNavItem>
    ))}
    <SideNavDivider />
    <UserMenu />
  </SideNav>
);

export default SideNavigation;
