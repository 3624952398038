import { useQuery, UseQueryOptions } from 'react-query';
import { useToast } from '@xpanseinc/nebula';
import { User } from '@xpanseinc/authorization-service-api-rest';
import { useAuthorizationApi } from '@/api';

const QUERY_KEY = () => ['authorization'];

export const useGetCurrentUser = (options?: UseQueryOptions<User, Error>) => {
  const toast = useToast();
  const authorizationApi = useAuthorizationApi();
  return useQuery<User, Error>(QUERY_KEY(), () => authorizationApi.getCurrentUser(), {
    ...options,
    onError: (error) => toast.negative(error?.message),
  });
};
