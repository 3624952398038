import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Spinner } from '@xpanseinc/nebula';

const Home = lazy(() => import('./home'));

const Four0Four = lazy(() => import('./404'));

const AppRoutes = () => (
  <Suspense fallback={<Spinner label="Loading Route" />}>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Four0Four />} />
    </Routes>
  </Suspense>
);

export default AppRoutes;
