import {
  BaseAPI,
  Configuration,
  AuthorizationServiceApi,
} from '@xpanseinc/authorization-service-api-rest';
import { useAuth0 } from '@auth0/auth0-react';

const useApiConfig = () =>
  new Configuration({
    basePath: `https://authorization-service-v1.${import.meta.env.VITE_XPANSE_API_DOMAIN}`,
  });

const useWithPreMiddleWare = <T extends BaseAPI>(api: T) => {
  const { getIdTokenClaims } = useAuth0();
  return api.withPreMiddleware<T>(async (context) => {
    const headers = new Headers(context.init.headers);
    const tokenClaims = await getIdTokenClaims();
    const token = tokenClaims?.__raw;

    headers.set('Authorization', `Bearer ${token}`);
    context.init.headers = headers;

    return context;
  });
};

export const useAuthorizationApi = () => {
  const config = useApiConfig();
  return useWithPreMiddleWare(new AuthorizationServiceApi(config));
};
