import styled from '@emotion/styled';
import { SideNavigation } from '@/components/layout';
import { Modal } from '@/components/modals';
import AppRoutes from '@/routes';

const AppContainer = styled.div`
  display: grid;
  grid-template-columns: var(--default-layout-left-width) var(--default-layout-right-width);
  height: 100vh;
`;

const MainLayout = styled.main`
  background-color: var(--space-01);
  padding: 28px 48px 0 48px;
  overflow: hidden;
  overflow-y: scroll;
`;

const App = () => (
  <AppContainer>
    <SideNavigation />
    <MainLayout>
      <AppRoutes />
    </MainLayout>
    <Modal />
  </AppContainer>
);

export default App;
